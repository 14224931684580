export const getPageMetaData = query => ({
  '/': { title: 'Spectrometer Dashboard', event: 'SPEDAS' },
  '^/melting/dashboard': { title: 'Melting Dashboard', event: 'DASHLP' },
  '^/inventory$': { title: 'Inventory', event: 'RMINLP' },
  '^/inventory/[0-9]+$': { title: 'Inventory Details', event: 'RMINDP' },
  '^/grades$': { title: 'Grades & Parts', event: 'G&PGLP' },
  '^/grades/add$': {
    title: query?.duplicate ? 'Select a Grade to Duplicate' : 'Add Grade',
    event: 'G&PGN'
  },
  '^/grades/[0-9]+/edit$': { title: 'Grade Details', event: 'G&PGDP' },
  '^/parts$': { title: 'Parts', event: 'G&PPLP' },
  '^/parts/add$': {
    title: query?.duplicate ? 'Select a Part to Duplicate' : 'Parts',
    event: 'G&PPN'
  },
  '^/parts/[0-9]+/edit$': { title: 'Part Details', event: 'G&PPDP' },
  '^/orders$': { title: 'Orders', event: 'PPORLP' },
  '^/orders/[0-9]+/$': { title: 'Order Details', event: 'PPORDP' },
  '^/cm/chargemix/$': { title: 'ChargeMix', event: 'CMLP' },
  '^/cm/chargemix/wizard/[0-9]+$': {
    title: 'ChargeMix Details',
    event: 'CMDET'
  },
  '^/cm/chargemix/wizard/$': { title: 'Create ChargeMix', event: 'CMDWIZ' },
  '^/login$': { title: 'Login', event: 'LOGINP' },
  '^/OTPlogin$': { title: 'Enter OTP', event: 'LOGINP_OTP' },
  '^/quote$': { title: 'Raise Rfq', event: 'RFQ' },
  '^/spectrometer$': { title: 'Spectrometer', event: 'SPELIP' },
  '^/spectrometer/[0-9]+$': {
    title: 'Spectrometer Readings',
    event: 'SPEDEP'
  },
  '^/pricing$': { title: 'Pricing & RFQ', event: 'PPRRLP' },
  '^/furnace/holding$': { title: 'Holding Furnace', event: 'CMHFLP' },
  '^/control$': { title: 'Control Plan', event: 'RMCPLP' },
  '^/control/add$': {
    title: 'Control Plan',
    event: `RMCPS${query?.step?.split?.('_')?.[1] || 1}`
  },
  '^/control/[0-9]+$': { title: 'Control Plan Details', event: 'RMCPDP' },
  '^/control/[0-9]+/edit$': {
    title: 'Control Plan Edit Details',
    event: 'RMCPEP'
  },
  '^/supplier$': { title: 'Supplier Matrix', event: 'RMSMLP' },
  '^/qualityreports$': { title: 'Quality Reports', event: 'RMQRLP' },
  '^/qualityreports/[0-9]+$': {
    title: 'Quality Reports Readings',
    event: 'RMQRDP'
  },
  '^/heats$': { title: 'Heats', event: 'HEATLP' },
  '^/heats/[0-9]+$': { title: 'Heat Details', event: 'HEATDET' },
  '^/productionplan$': { title: 'Heat Plan', event: 'PPLIPG' },
  '^/productionplan/[0-9]+$': {
    title: 'Heat Plan Details',
    event: 'PPDEPG'
  },
  '^/productionplan/add': {
    title: 'Create Heat Plan',
    event: `PPNPS${query?.step || 1}`
  },
  '^/productionplan/plan$': { title: 'Production Plan', event: 'PPPPLP' },
  '^/productionplan/plan[0-9]+$': {
    title: 'Production Plan Details',
    event: 'PPPPLP'
  },
  '^/productionplan/plan/add': {
    title: 'Create Production Plan',
    event: `PPPPS${query?.step || 1}`
  },
  '^/materialtest/tensile$': { title: 'Tensile', event: 'MTTELP' },
  '^/materialtest$': { title: 'Hardness', event: 'MTHALP' },
  '^/materialtest/microstructure$': {
    title: 'Microstructure',
    event: 'MTMILP'
  }
});
