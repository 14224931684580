import { verifyHeatName } from '../../api/ProductionPlan';
import { rawMaterialInitData } from '../../constants/ProductionPlan';

/**
 * Transforms and filters the input data to create a formatted object suitable for adding a production plan.
 *
 * This function processes the input data by extracting and formatting specific properties:
 * - Converts `grade`, `furnace`, and `ladle` to their respective IDs.
 * - Parses and formats `tapping_min` and `tapping_max` to two decimal places.
 * - Filters and maps `production_casting_plans` to include only those with valid `part_no` IDs,
 *   and formats their properties.
 * - Flattens, filters, and maps `production_raw_materials` to include only those with valid `raw_material` IDs,
 *   and formats their quantities to three decimal places.
 *
 * @param {Object} data - The input data for creating the production plan.
 * @param {Object} data.grade - The grade object containing an ID.
 * @param {Object} data.furnace - The furnace object containing an ID.
 * @param {Object} data.ladle - The ladle object containing an ID.
 * @param {string} data.tapping_min - The minimum tapping temperature as a string.
 * @param {string} data.tapping_max - The maximum tapping temperature as a string.
 * @param {Array<Object>} data.production_casting_plans - The array of casting plans.
 * @param {Object} data.production_casting_plans[].part_no - The part number object containing an ID.
 * @param {string} data.production_casting_plans[].no_of_moulds - The number of moulds as a string.
 * @param {Object} data.production_raw_materials - The raw materials grouped by type.
 * @returns {Object} The formatted data object ready for adding a production plan.
 */
export const createAddPlanData = data => {
  return {
    grade: data.grade?.id,
    shift: data.shift?.id,
    planned_date: data.planned_date,
    tapping_min: parseFloat(data.tapping_min).toFixed(2),
    tapping_max: parseFloat(data.tapping_max).toFixed(2),
    uom_additives: 'KG',
    uom_furnace: 'KG',
    uom_ladle: 'KG',
    uom_nodularizer: 'KG',
    production_casting_plans: data.production_casting_plans
      ?.filter(plan => plan?.part_no?.id)
      .map(plan => ({
        part_no: plan.part_no?.id, // Assuming default part number if not provided
        no_of_moulds: plan.no_of_moulds && parseInt(plan.no_of_moulds, 10)
      })),
    production_raw_materials: Object.values(data.production_raw_materials)
      .flat()
      .map(item => ({
        raw_material: item.raw_material.id,
        quantity: item?.quantity ? item.quantity : null
      }))
      .filter(item => item.raw_material)
  };
};

/**
 * Processes raw material data by grouping them into categories and ensuring each category has at least one element.
 *
 * This function takes in an array of raw material data and groups the materials into categories (furnace, additives,
 * ladle, nodularizer) based on the `cm_type` property of each item. Optionally, a grouping key can be provided to
 * specify a nested object from which the `cm_type`, `id`, and `name` properties are extracted. The function ensures
 * that each category has at least one element by adding a default element if necessary.
 *
 * @param {Array<Object>} data - The raw material data to be processed.
 * @param {string} [groupingKey=null] - Optional key to access nested objects for grouping.
 * @returns {Object} An object with keys representing the categories (furnace, additives, ladle, nodularizer), each
 *                   containing an array of raw material objects with `id`, `name`, and `quantity` properties.
 */
export const processRawMaterials = (data, groupingKey = null) => {
  const outputData = data.reduce(
    (acc, item) => {
      const { quantity = '', actual_qty = '' } = item;
      const { cm_type, id, name } = item?.[groupingKey] || item;
      const type = cm_type.toLowerCase();
      if (acc[type]) {
        acc[type].push({
          raw_material: { id, name },
          quantity: quantity ? parseFloat(quantity).toFixed(2) : '',
          actual_qty
        });
      }
      return acc;
    },
    { furnace: [], additives: [], ladle: [], nodularizer: [] }
  );

  // Ensure each array has at least one element with rawMaterialInitData if empty
  Object.keys(outputData).forEach(key => {
    if (outputData[key].length === 0) {
      outputData[key].push(rawMaterialInitData);
    }
  });

  return outputData;
};

/**
 * Calculates the percentage of each raw material relative to the furnace capacity.
 *
 * This function takes in a data object containing raw materials and their respective units of measure (UOM).
 * It calculates the total quantity of raw materials in the furnace category and then determines the percentage
 * of each raw material relative to this total quantity.
 *
 * @param {Object} data - The data object containing raw materials and UOM information.
 * @param {Object} data.production_raw_materials - Object containing arrays of raw material data for each category.
 * @param {string} data.uom_furnace - Unit of measure for furnace raw materials.
 * @param {string} data.uom_additives - Unit of measure for additives.
 * @param {string} data.uom_ladle - Unit of measure for ladle raw materials.
 * @param {string} data.uom_nodularizer - Unit of measure for nodularizer raw materials.
 * @returns {Object} An object where each key represents a category of raw materials, and each value is an array
 *                   of raw materials with their quantities and their respective percentages relative to the
 *                   total furnace quantity.
 */
export const getRawMaterialsPercentage = data => {
  const {
    production_raw_materials = [],
    uom_furnace,
    uom_additives,
    uom_ladle,
    uom_nodularizer
  } = data;

  const uomMap = {
    furnace: uom_furnace,
    additives: uom_additives,
    ladle: uom_ladle,
    nodularizer: uom_nodularizer
  };

  // Calculate the total quantity for the furnace category
  const totalFurnaceQuantity = (production_raw_materials?.furnace || []).reduce(
    (sum, { quantity = 0 }) => sum + Number(quantity),
    0
  );

  return Object.entries(production_raw_materials)?.reduce(
    (acc, [key, materials]) => {
      acc[key] = materials
        .map(({ raw_material: { id, name }, quantity, actual_qty }) => {
          const qty = quantity || 0;
          // Calculate the percentage using the total quantity
          const percentage = totalFurnaceQuantity
            ? (qty / totalFurnaceQuantity) * 100
            : 0;

          return {
            raw_material: id,
            name: name,
            quantity: qty,
            actual_qty,
            percentage: percentage.toFixed(2)
          };
        })
        .filter(({ raw_material }) => raw_material);

      return acc;
    },
    {}
  );
};

/**
 * normalizeChemicalAnalysisData function
 *
 * This function normalizes the chemical analysis data by grouping readings based on their sample type.
 *
 * @param {Object} data - The raw chemical analysis data object.
 * @param {Array} data.readings - An array of readings, each containing a sample_type and other properties.
 *
 * @returns {Object} - The normalized data object with grouped readings.
 * @returns {Array} groupedReadings - An object where keys are sample types and values are arrays of readings.
 *
 * @example
 * const rawData = {
 *   readings: [
 *     { sample_type: 'Final', ...otherProps },
 *     { sample_type: 'Bath', ...otherProps },
 *     { sample_type: 'Final', ...otherProps },
 *     // more readings
 *   ]
 * };
 *
 * const normalizedData = normalizeChemicalAnalysisData(rawData);
 * // normalizedData.groupedReadings = {
 * //   Final: [{ ... }, { ... }],
 * //   Bath: [{ ... }]
 * // }
 */
export const normalizeChemicalAnalysisData = data => {
  const groupedReadings = data?.readings?.reduce((acc, reading) => {
    if (reading?.sample_type) {
      if (!acc[reading.sample_type]) {
        acc[reading.sample_type] = [];
      }
      acc[reading.sample_type].push(reading);
    }
    return acc;
  }, {});

  return {
    ...data,
    groupedReadings
  };
};

/**
 * Formats a given date string into a readable date and time format.
 *
 * @param {string} displayDate - The date string to format. Expected in ISO format.
 * @param {boolean} [includeDate=true] - Whether to include the date in the output. Default is true.
 * @returns {string} - Formatted date and time string. If includeDate is false, only time is returned.
 *
 * @example
 * // returns "2024-08-02 | 4:10 PM"
 * displayDateAndTime('2024-08-02T16:10:44.820436+05:30');
 *
 * @example
 * // returns "4:10 PM"
 * displayDateAndTime('2024-08-02T16:10:44.820436+05:30', false);
 */
export const displayDateAndTime = (displayDate, includeDate = true) => {
  if (!displayDate) return '';

  const dateObject = new Date(displayDate);

  let hours = dateObject.getHours();
  const minutes = dateObject.getMinutes();
  const ampm = hours >= 12 ? 'PM' : 'AM';

  hours = hours % 12 || 12; // the hour '0' should be '12'
  const formattedTime = dateObject.toLocaleTimeString('en-US', {
    hour: '2-digit',
    minute: '2-digit'
  });

  if (includeDate) {
    const formattedDate = dateObject.toISOString().split('T')[0];
    return `${formattedDate} | ${formattedTime}`;
  }

  return formattedTime;
};

/**
 * Validates whether a given value has more than the allowed number of decimal places.
 *
 * @param {string} value - The input value to be validated.
 * @param {number} maxDecimals - The maximum number of decimal places allowed.
 * @returns {boolean} - Returns true if the value is valid, otherwise false.
 */
export const validateDecimalValue = (value, maxDecimals = 2) => {
  // Regex to check if the value has more than maxDecimals decimal places
  const regex = new RegExp(`^\\d*(\\.\\d{0,${maxDecimals}})?$`);

  return regex.test(value); // Test the value against the regex
};

/**
 * Calculates the total liquid metal weight for the production casting plans.
 * @returns {number} The total liquid metal weight. Returns `0` if the
 * `data prop` is undefined or empty.
 */
export const calculateLiqMetalWt = (data = []) => {
  if (!data) return 0;

  return data?.reduce((acc, item) => {
    const moulds = Number(item?.no_of_moulds);
    const bunchWt = Number(item?.part_no?.weight) || 0;
    const calculatedLiqMetalWt = moulds * bunchWt;

    return acc + (isNaN(calculatedLiqMetalWt) ? 0 : calculatedLiqMetalWt);
  }, 0);
};

export const calculateMeanQty = (min_qty, max_qty) => {
  // Convert inputs to numbers if they are not null/empty
  const min = parseFloat(min_qty);
  const max = parseFloat(max_qty);

  // Collect valid numbers in an array
  const quantities = [min, max].filter(qty => !isNaN(qty));

  // If the array has values, calculate the mean; otherwise, return an empty string
  if (quantities.length > 0) {
    const mean =
      quantities.reduce((sum, qty) => sum + qty, 0) / quantities.length;
    return mean.toFixed(2);
  }

  return '';
};
