import config from '../config/config';
import { CHARGEMIX_COMMON_CONSTS } from '../constants/ChargeMix/common';
import {
  STATUS_CONSTANTS,
  castingPlanInitialData
} from '../constants/ProductionPlan';
import { createQueryParam } from '../utils';
import { processRawMaterials } from '../utils/ProductionPlan';
import { cFetch, token } from './apiUtils';

/**
 * Fetches heat distribution data from the API.
 * @param {Object} params - The parameters for the API request.
 * @returns {Array} - The heat distribution data.
 */
export const getProductionPlanData = async params => {
  const queryParam = createQueryParam(params);
  const response = await cFetch(
    `${config.API_URL}/api/production_plan/list/?${queryParam}`,
    {
      method: 'GET',
      headers: {
        authorization: token()
      }
    }
  );
  return response;
};

/**
 * Fetches part names for the production plan from the API based on provided parameters.
 *
 * @param {Object} params - The parameters for the API request.
 * @returns {Array} - The part names data.
 */

export const getProductionPlanPartnames = async params => {
  const queryParam = createQueryParam(params);
  const response = await cFetch(
    `${config.API_URL}/api/c/partname_dropdown/?${queryParam}`,
    {
      method: 'GET',
      headers: {
        authorization: token()
      }
    }
  );
  return response;
};

/**
 * Fetches part numbers for the production plan from the API based on provided parameters.
 *
 * @param {Object} params - The parameters for the API request.
 * @returns {Array} - The part numbers data options.
 */

export const getProductionPlanPartNumber = async params => {
  const queryParam = createQueryParam(params);
  const response = await cFetch(
    `${config.API_URL}/api/c/partno_dropdown/?${queryParam}`,
    {
      method: 'GET',
      headers: {
        authorization: token()
      }
    }
  );
  return response;
};

/**
 * Fetches furnace data for the production plan from the API.
 *
 * @returns {Array} - The furnace data options.
 */
export const getProductionPlanFurnace = async () => {
  const response = await cFetch(`${config.API_URL}/api/dropdown/furnace/`, {
    method: 'GET',
    headers: {
      authorization: token()
    }
  });
  return response;
};

/**
 * Fetches shift data for the production plan from the API.
 *
 * @returns {Array} - The shift data options.
 */
export const getProductionPlanShifts = async () => {
  const response = await cFetch(`${config.API_URL}/api/dropdown/shift/`, {
    method: 'GET',
    headers: {
      authorization: token()
    }
  });
  return response;
};

/**
 * Fetches ladle data for the production plan from the API.
 *
 * @returns {Array} - The ladle data options.
 */
export const getProductionPlanLadle = async () => {
  const response = await cFetch(`${config.API_URL}/api/dropdown/ladle/`, {
    method: 'GET',
    headers: {
      authorization: token()
    }
  });
  return response;
};

/**
 * Fetches grade data for the production plan from the API based on provided parameters.
 *
 * @param {Object} params - The parameters for the API request.
 * @returns {Array} - The grade data options.
 */
export const getProductionPlanGrade = async params => {
  const queryParam = createQueryParam(params);
  const response = await cFetch(
    `${config.API_URL}/api/dropdown/grade/?${queryParam}`,
    {
      method: 'GET',
      headers: {
        authorization: token()
      }
    }
  );
  return response;
};

/**
 * Fetches tagged items for the production plan from the API based on provided parameters.
 *
 * @param {Object} params - The parameters for the API request.
 * @returns {Array} - The tagged items data.
 */
export const getProductionPlanTaggedItems = async params => {
  const queryParam = createQueryParam(params);
  const response = await cFetch(
    `${config.API_URL}/api/c/grade-part-chargemix/?${queryParam}`,
    {
      method: 'GET',
      headers: {
        authorization: token()
      }
    }
  );
  return response;
};

/**
 * Fetches the details of a specific production plan from the API.
 *
 * @param {number} id - The ID of the production plan.
 * @returns {Object} - The detailed production plan data modified to be stored in redux
 */
export const getProductionPlanDetail = async id => {
  let readingsResponse = {};
  let selectedShift = {};
  const response = await cFetch(
    `${config.API_URL}/api/production_plan/detail/${id}/`,
    {
      method: 'GET',
      headers: {
        authorization: token()
      }
    }
  );
  // Check if second API call is needed
  if (response.heat && response.status === STATUS_CONSTANTS.COMPLETED) {
    try {
      readingsResponse = await cFetch(
        `${config.API_URL}/api/iot/spectrometer_v2/${response.heat}/`,
        {
          method: 'GET',
          headers: {
            authorization: token()
          }
        }
      );
    } catch (err) {
      console.error('Failed to fetch spectrometer data:', err);
    }
  }

  if (response.shift) {
    const shiftOptions = await getProductionPlanShifts();
    selectedShift = shiftOptions?.find(({ id }) => id == response.shift);
  }

  const updatedRawMaterials = processRawMaterials(
    response?.production_raw_materials,
    'raw_material'
  );

  const updatedCastings = response?.production_casting_plans?.map(
    ({ cavities_per_mould, weight, ...cast }) => {
      return {
        ...cast,
        part_no: {
          id: cast?.part_no?.id,
          number: cast?.part_no?.number,
          cavities_per_mould,
          weight
        },
        part_name: {
          id: cast?.part_no?.part_name?.id,
          name: cast?.part_no?.part_name?.name
        }
      };
    }
  );

  const updatedHeatTaggedRawMaterials = [
    ...response?.heat_plan_tagged_materials,
    ...(response?.additional_suggestions || [])
      .map(sugg => ({
        ...sugg,
        raw_material: {
          ...sugg.raw_material,
          name: sugg.raw_material?.item_name
        },
        qty: null,
        suggested_qty: sugg?.raw_material?.fake_opt_qty || null
      }))
      .filter(
        ({ raw_material }) =>
          raw_material.var_name !==
          CHARGEMIX_COMMON_CONSTS.LIQUID_METAL_WT_VAR_NAME
      )
  ];

  const updatedResponse = {
    ...response,
    shift: selectedShift,
    heat_plan_tagged_materials: updatedHeatTaggedRawMaterials,
    production_casting_plans: (updatedCastings.length && updatedCastings) || [
      castingPlanInitialData
    ],
    production_raw_materials: updatedRawMaterials,
    readings: readingsResponse?.readings,
    cm_target_chemistry: readingsResponse?.cm_data?.cm_target_chemistry,
    cm_bath_target_chemistry:
      readingsResponse?.cm_data?.cm_bath_target_chemistry
  };
  return updatedResponse;
};

/**
 * Creates a new production plan by sending the provided form data to the API.
 *
 * @param {Object} formData - The data for the new production plan.
 * @returns {Object} - The response from the API.
 */
export const createProductionPlan = async formData => {
  try {
    const response = await cFetch(
      `${config.API_URL}/api/production_plan/create/?version=2`,
      {
        method: 'POST',
        headers: {
          authorization: token(),
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(formData)
      }
    );
    return response;
  } catch (err) {
    console.log('Error updating plan', err);
    throw err;
  }
};

/**
 * Updates an existing production plan by sending the provided form data to the API.
 *
 * @param {number} id - The ID of the production plan to update.
 * @param {Object} formData - The updated data for the production plan.
 * @returns {Object} - The response from the API.
 */
export const updateProductionPlan = async (id, formData) => {
  try {
    const response = await cFetch(
      `${config.API_URL}/api/production_plan/update/${id}/?version=2`,
      {
        method: 'PATCH',
        headers: {
          authorization: token(),
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(formData)
      }
    );
    return response;
  } catch (err) {
    console.log('Error updating plan', err);
    throw err;
  }
};

/**
 * Fetches the count of heat plans by their status from the production plan API.
 *
 * This function makes a GET request to the `/api/production_plan/heat_plan_status` endpoint
 * to retrieve the count of heat plans grouped by their status (e.g., Completed, Ongoing, Pending).
 * The request includes an authorization token and sets the content type to JSON.
 *
 * @returns {Promise<Object>} A promise that resolves to the response data from the API.
 *                            The response data contains the count of heat plans by status.
 *
 * @throws {Error} If the fetch request fails or the response is not in JSON format.
 */
export const getHeatPlanStatusCount = async query => {
  const queryParam = createQueryParam(query);
  const response = await cFetch(
    `${config.API_URL}/api/production_plan/heat_plan_status/?${queryParam}`,
    {
      method: 'GET',
      headers: {
        authorization: token(),
        'Content-Type': 'application/json'
      }
    }
  );
  return response;
};
